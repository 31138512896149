<template>
  <div class="container mt-5 title-section">
    <TitleBar
      :pageTitleCustom="
        id && id != '' ? 'Update Configuration' : 'Add Configuration'
      "
    ></TitleBar>
    <span class="title-border-b" />
    <div class="text-danger" v-if="errorMsg != ''">
      {{ errorMsg }}
    </div>
    <form @submit.prevent="submit">
      <div class="row g-3">
        <div class="col-sm-10" v-if="!id || id == ''">
          <label for="Bank" class="form-label">Company</label>
          <select
            v-model="formData.companyId"
            class="form-control"
            id="companyId"
            placeholder=""
          >
            <option value="">Please select</option>
            <option v-for="c in companydata" :key="c.id" v-bind:value="c.id">
              {{ c.companyName }}
            </option>
          </select>
          <span
            class="text-danger"
            v-for="error in v$.companyId.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <input
          v-else
          v-model="formData.companyId"
          type="text"
          class="form-control hidden"
          id="companyId"
          placeholder=""
        />
        <div class="col-sm-10">
          <label for="minPerTransaction" class="form-label"
            >Min Per Transaction</label
          >
          <input
            v-model="formData.minPerTransaction"
            type="number"
            class="form-control"
            id="minPerTransaction"
            placeholder=""
            step="any"
          />
          <span
            class="text-danger"
            v-for="error in v$.minPerTransaction.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-10">
          <label for="maxPerTransaction" class="form-label"
            >Max Per Transaction</label
          >
          <input
            v-model="formData.maxPerTransaction"
            type="number"
            class="form-control"
            id="maxPerTransaction"
            placeholder=""
            step="any"
          />
          <span
            class="text-danger"
            v-for="error in v$.maxPerTransaction.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-10">
          <label for="maxPerMonth" class="form-label">Max Per Month</label>
          <input
            v-model="formData.maxPerMonth"
            type="number"
            class="form-control"
            id="maxPerMonth"
            placeholder=""
            step="any"
          />
          <span
            class="text-danger"
            v-for="error in v$.maxPerMonth.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-10">
          <label for="transactionFees" class="form-label"
            >Transaction Fees</label
          >
          <input
            v-model="formData.transactionFees"
            type="number"
            class="form-control"
            id="transactionFees"
            placeholder=""
            step="any"
          />
          <span
            class="text-danger"
            v-for="error in v$.transactionFees.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-10">
          <label for="startDay" class="form-label">Start Day</label>
          <input
            v-model="formData.startDay"
            type="number"
            class="form-control"
            id="startDay"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.startDay.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-10">
          <label for="cutOffDay" class="form-label">Cut Off Day</label>
          <input
            v-model="formData.cutOffDay"
            type="number"
            class="form-control"
            id="cutOffDay"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.cutOffDay.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
          <p style="margin: 0; margin-top: 10px">
            (Enter 0 for the end of the month.)
          </p>
        </div>
        <div class="col-sm-10">
          <label for="cutOffDay" class="form-label">Block Start Day</label>
          <input
            v-model="formData.blockStart"
            type="number"
            class="form-control"
            id="cutOffDay"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.blockStart.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-10">
          <label for="cutOffDay" class="form-label">Block End Day</label>
          <input
            v-model="formData.blockEnd"
            type="number"
            class="form-control"
            id="cutOffDay"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.blockEnd.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-10">
          <label for="totalWorkingDay" class="form-label"
            >Total Working Day</label
          >
          <input
            v-model="formData.totalWorkingDay"
            type="number"
            class="form-control"
            id="totalWorkingDay"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.totalWorkingDay.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-10">
          <label for="allowAppCompanyLogo" class="form-label"
            >Curlec Integration</label
          >
          <select
            v-model="formData.allowCurlecIntegration"
            class="form-control"
            id="allowCurlecIntegration"
            placeholder=""
          >
            <option value="1">Enable</option>
            <option value="0">Disable</option>
          </select>
          <span
            class="text-danger"
            v-for="error in v$.allowCurlecIntegration.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>

        <div class="col-sm-10">
          <label for="insuranceType" class="form-label">Select Insurance</label>
          <select
            v-model="formData.insuranceType"
            class="form-control"
            id="insuranceType"
            placeholder=""
          >
            <option
              :value="insurance.insuranceType"
              v-for="insurance in insuranceList"
              :key="insurance.insuranceType"
            >
              {{ insurance.insuranceName }}
            </option>
          </select>
          <span
            class="text-danger"
            v-for="error in v$.insuranceType?.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-10" v-if="formData.insuranceType !== 0">
          <label for="transactionFees" class="form-label">Premium Fees</label>
          <input
            v-model="formData.insuranceFee"
            type="number"
            class="form-control"
            id="transactionFees"
            placeholder=""
            step="any"
          />
          <span
            class="text-danger"
            v-for="error in v$.insuranceFee?.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>

        <div class="col-sm-10">
          <label for="allowedWithdrawals" class="form-label"
            >No. of Allowed Withdrawals per Month</label
          >
          <input
            v-model="formData.noOfAllowedWithdrawals"
            type="number"
            class="form-control"
            id="allowedWithdrawals"
            placeholder=""
            step="any"
          />
          <span
            class="text-danger"
            v-for="error in v$.noOfAllowedWithdrawals?.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-10">
          <label for="allowAppCompanyLogo" class="form-label"
            >Application Company Logo</label
          >
          <select
            v-model="formData.allowAppCompanyLogo"
            class="form-control"
            id="allowAppCompanyLogo"
            placeholder=""
          >
            <option value="1">Enable</option>
            <option value="0">Disable</option>
          </select>
          <span
            class="text-danger"
            v-for="error in v$.allowAppCompanyLogo.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>

        <div class="col-sm-10">
          <label for="allowAutoWithdrawRequest" class="form-label"
            >Auto Withdrawal Request</label
          >
          <select
            v-model="formData.allowAutoWithdrawRequest"
            s
            class="form-control"
            id="allowAutoWithdrawRequest"
            placeholder=""
          >
            <option value="1">Enable</option>
            <option value="0">Disable</option>
          </select>
          <span
            class="text-danger"
            v-for="error in v$.allowAutoWithdrawRequest.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-10" v-if="formData.allowAutoWithdrawRequest == 1">
          <label for="autoWithdrawRequestPayoutDate" class="form-label"
            >Auto Withdrawal Request Payday</label
          >
          <input
            v-model="formData.autoWithdrawRequestPayoutDate"
            type="number"
            class="form-control"
            id="autoWithdrawRequestPayoutDate"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.autoWithdrawRequestPayoutDate.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-10" v-if="formData.allowAutoWithdrawRequest == 1">
          <label for="allowAutoWithdrawRequest" class="form-label"
            >Auto Withdrawal Request Rank</label
          >
          <WithdrawRankTable
            :WRDataArray="formData.WRDataArray"
            @data-updated="WRDataUpdate"
          />
        </div>
      </div>

      <div class="pddtop30">
        <button class="btn btn-secondary mrgrbtn" type="button" @click="goBack">
          Back
        </button>
        <button class="btn btn-primary" type="submit">Submit</button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import store from "@/store";
import axios from "axios";
import { defineComponent, ref, Ref, computed, reactive, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  helpers,
  decimal,
  numeric,
  minValue,
  maxValue,
  requiredIf,
} from "@vuelidate/validators";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import WithdrawRankTable from "@/components/WithdrawRankTable.vue";
import TitleBar from "@/components/TitleBar.vue";
export default defineComponent({
  name: "AdminConfigurationEditor",
  components: {
    WithdrawRankTable,
    TitleBar,
  },
  data() {
    return {
      id: "",
      companydata: null,
    };
  },
  setup() {
    const toast = useToast();
    const router = useRouter();
    const token = store.state.token;
    const errorMsg = ref("");
    const sending = ref(false);
    const configuration = ref({}) as Ref<any>;
    const insuranceList = ref([]);
    const loading = ref(true);
    const selectedInsurance = computed(() => {
      const found = insuranceList.value.find(
        (list) => configuration.value.insuranceType === list.insuranceType
      );
      if (!found) return {};
      return { ...found, insuranceFee: configuration.value.insuranceFee };
    });

    const formData = reactive({
      pid: 0,
      Id: 0,
      minPerTransaction: "",
      maxPerMonth: "",
      maxPerTransaction: "",
      transactionFees: "",
      startDay: "",
      cutOffDay: "",
      blockStart: "",
      blockEnd: "",
      totalWorkingDay: "",
      companyId: "",
      noOfAllowedWithdrawals: 0,
      allowAppCompanyLogo: 0,
      allowAutoWithdrawRequest: 0,
      autoWithdrawRequestPayoutDate: 0,
      autoWithdrawRank: "",
      insuranceType: 0,
      insuranceFee: 0,
      allowCurlecIntegration: 0,
      WRDataArray: [
        { firstField: 0, secondField: 3000, thirdField: 500 },
        { firstField: 3001, secondField: 5000, thirdField: 1000 },
      ],
    });

    // Sets the default insurance fee if it was not set initially
    watch(
      () => formData.insuranceType,
      (now, prev) => {
        if (
          now !== 0 &&
          prev === 0 &&
          !loading.value &&
          selectedInsurance.value.insuranceType === 0
        ) {
          const found = insuranceList.value.find(
            (i) => i.insuranceType === formData.insuranceType
          );
          if (found) {
            formData.insuranceFee = found.insuranceDefaultFee;
          }
        }
      }
    );

    const submit = async () => {
      if (sending.value) return;
      sending.value = true;
      const result = await v$.value.$validate();
      if (formData.allowAutoWithdrawRequest == 0) {
        formData.autoWithdrawRequestPayoutDate = 0;
        formData.autoWithdrawRank = "";
      }

      if (formData.WRDataArray.length > 0) {
        formData.autoWithdrawRank = "";
        formData.WRDataArray.forEach((value, index) => {
          if (formData.autoWithdrawRank == "") {
            formData.autoWithdrawRank =
              value.firstField +
              "@" +
              value.secondField +
              "@" +
              value.thirdField;
          } else {
            formData.autoWithdrawRank =
              formData.autoWithdrawRank +
              "," +
              value.firstField +
              "@" +
              value.secondField +
              "@" +
              value.thirdField;
          }
        });
      }

      if (formData.insuranceType === 0) {
        formData.insuranceFee = 0;
      }

      if (result) {
        if (formData.pid && formData.pid != 0) {
          console.log(formData.noOfAllowedWithdrawals);

          await axios
            .put(
              process.env.VUE_APP_API_URL + "Configuration",
              {
                ...formData,
                allowCurlecIntegration: parseInt(
                  formData.allowCurlecIntegration + ""
                ),
              },
              {
                headers: {
                  Authorization: "bearer " + token,
                },
              }
            )
            .then((res) => {
              errorMsg.value = "";

              toast.success("Configuration successfully updated", {
                timeout: 3000,
              });
              sending.value = false;
              router.push({
                name: "admin-company-details",
                params: { id: formData.companyId },
              });
            })
            .catch((err) => {
              sending.value = false;
              errorMsg.value = err.response.data;
            });
        } else {
          await axios
            .post(process.env.VUE_APP_API_URL + "Configuration", formData, {
              headers: {
                Authorization: "bearer " + token,
              },
            })
            .then((res) => {
              errorMsg.value = "";

              toast.success("Configuration successfully added", {
                timeout: 3000,
              });
              sending.value = false;
              router.push({
                name: "admin-company-details",
                params: { id: formData.companyId },
              });
            })
            .catch((err) => {
              sending.value = false;
              errorMsg.value = err.response.data;
            });
        }
      }
    };

    const rules = computed(() => {
      return {
        companyId: {
          required: helpers.withMessage("Please Select Company", required),
        },
        minPerTransaction: {
          required: helpers.withMessage(
            "Please enter Min Per Transaction",
            required
          ),
          decimal,
        },
        maxPerMonth: {
          required: helpers.withMessage("Please enter Max Per Month", required),
          decimal,
        },
        maxPerTransaction: {
          required: helpers.withMessage(
            "Please enter Max Per Transaction",
            required
          ),
          decimal,
        },
        transactionFees: {
          required: helpers.withMessage(
            "Please enter Transaction Fees",
            required
          ),
          decimal,
        },
        startDay: {
          required: helpers.withMessage("Please enter Cut Off Day", required),
          numeric,
        },
        cutOffDay: {
          required: helpers.withMessage("Please enter Cut Off Day", required),
          numeric,
        },
        blockStart: {
          numeric,
        },
        blockEnd: {
          numeric,
        },
        totalWorkingDay: {
          required: helpers.withMessage(
            "Please enter Total Working Day",
            required
          ),
          numeric,
        },
        noOfAllowedWithdrawals: {},
        allowAppCompanyLogo: {},
        allowCurlecIntegration: {},
        allowAutoWithdrawRequest: {},
        autoWithdrawRequestPayoutDate: {
          requiredIf: helpers.withMessage(
            "Please enter Auto Withdrawal Request Payday",
            requiredIf(() => formData.allowAutoWithdrawRequest == 1)
          ),
          minValue: helpers.withMessage(
            "Please enter a value between 1 and 31.",
            minValue(1)
          ),
          maxValue: helpers.withMessage(
            "Please enter a value between 1 and 31.",
            maxValue(31)
          ),
        },
      };
    });
    const v$ = useVuelidate(rules, formData);

    async function getInsuranceList() {
      try {
        const token = store.state.token;
        const response = await axios.get(
          process.env.VUE_APP_API_URL + "Insurance/list",
          {
            headers: {
              Authorization: "bearer " + token,
            },
          }
        );
        insuranceList.value = response.data;
      } catch (error) {
        //
      }
    }
    getInsuranceList();
    return {
      v$,
      formData,
      token,
      router,
      toast,
      errorMsg,
      submit,
      sending,
      selectedInsurance,
      insuranceList,
      loading,
      configuration,
    };
  },

  methods: {
    WRDataUpdate(updatedWRDataArray) {
      this.formData.WRDataArray = updatedWRDataArray;
    },
    async configurationDetails() {
      const token = store.state.token;
      const response = await axios
        .get(
          process.env.VUE_APP_API_URL +
            "Configuration/" +
            this.id +
            "/company/details",
          {
            headers: {
              Authorization: "bearer " + token,
            },
          }
        )
        .then((res) => {
          this.configuration = res.data;
          this.formData.Id = res.data.id;
          this.formData.minPerTransaction = res.data.minPerTransaction;
          this.formData.maxPerTransaction = res.data.maxPerTransaction;
          this.formData.maxPerMonth = res.data.maxPerMonth;
          this.formData.companyId = res.data.companyId;
          this.formData.transactionFees = res.data.transactionFees;
          this.formData.startDay = res.data.startDay;
          this.formData.cutOffDay = res.data.cutOffDay;
          this.formData.blockStart = res.data.blockStart;
          this.formData.blockEnd = res.data.blockEnd;
          this.formData.pid = this.id;
          this.formData.totalWorkingDay = res.data.totalWorkingDay;
          this.formData.insuranceType = res.data.insuranceType;
          this.formData.insuranceFee = res.data.insuranceFee;
          this.formData.noOfAllowedWithdrawals =
            res.data.noOfAllowedWithdrawals;

          this.formData.allowAppCompanyLogo = res.data.allowAppCompanyLogo;
          this.formData.allowCurlecIntegration =
            res.data.allowCurlecIntegration;
          this.formData.allowAutoWithdrawRequest = res.data
            .allowAutoWithdrawRequest
            ? res.data.allowAutoWithdrawRequest
            : 0;
          this.formData.autoWithdrawRequestPayoutDate = res.data
            .autoWithdrawRequestPayoutDate
            ? res.data.autoWithdrawRequestPayoutDate
            : 15;

          this.formData.autoWithdrawRank = res.data.autoWithdrawRank;
          if (this.formData.autoWithdrawRank) {
            this.formData.WRDataArray.splice(0);
            this.formData.WRDataArray = [];
            var splitAWR = this.formData.autoWithdrawRank.split(",");
            splitAWR.forEach((value, index) => {
              var splitAWRinside = value.split("@");
              this.formData.WRDataArray.push({
                firstField: splitAWRinside[0],
                secondField: splitAWRinside[1],
                thirdField: splitAWRinside[2],
              });
            });
          }
        })
        .catch((err) => {
          //
        });
      this.loading = false;
    },
    async getCCompanylist() {
      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "Configuration/companywithoutc/list",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.loading = false;
      this.companydata = response.data;
    },
    goBack() {
      this.$router.replace({
        name: "admin-company-details",
        params: {
          id: this.id,
        },
      });
    },
  },
  validations() {
    return {};
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.configurationDetails();
    } else {
      this.getCCompanylist();
    }
  },
});
</script>
